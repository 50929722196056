
import email from "../assets/email.svg";
import "./Contact.css";
export const Contact = ()=>{
    return(
       
    <div className="containerContact">
    
        <h1 className="title">Contacto</h1>
        
        <div className="containerEmailLogo">
            <div className="email-image">
                <img src={email} alt="email" />
            </div>
        </div>
        <div className="containterAddress">
            <address>
            Correo de contacto : <a href="mailto:contacto@jonathanvenegas.cl">contacto@jonathanvenegas.cl</a>
          </address>
        </div>
    </div>
    )
}