import { Logo } from "../components/Logo";
import { Menu } from "../components/Menu";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import MenuContext  from "../context/MenuContext";
import { useContext } from "react";
import "./Nav.css";

export const Nav = ()=>{

    const {menu ,buttonMenu} = useContext(MenuContext);


    return(

            <div className="containerNav" >
                <div className="containerLogo">
                    <Logo />
                </div>
                <div className="containerHamb">
                {
                    menu ?
                    <AiOutlineClose size="30px" onClick={()=>buttonMenu() }/> 
                    :
                    <GiHamburgerMenu size="30px" onClick={()=>buttonMenu() }  /> 
                }
                </div>
                <div className="containerlinkNavigator">
                   <Menu/>
                </div>
            </div>

    )
}