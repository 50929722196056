import { BsGithub, BsTwitter, BsLinkedin } from "react-icons/bs";
import "./Footer.css";

export const Footer = ()=>{

const size = "25px";
const color = "#0F2027";

    return(
    
    <div className="footer">
      
        <div className="containerRRSS">
            <a href="https://github.com/dr-Maker">
                 <BsGithub size={size} color={color}/>
            </a>
            <a href="https://twitter.com/Ck_DMaker">
            <BsTwitter size={size} color={color}/>
            </a>
            <a href="https://www.linkedin.com/in/jonathan-venegas-aliaga-07b8181a/">
            <BsLinkedin size={size} color={color}/>
            </a>
        </div>
        <p>Todos los derechos reservados &reg; </p>
    </div>
    )

}