import { Link } from "react-router-dom";
export const Menu = ()=>{
    return(
        <ul>
            <li>
                <Link to="/" >Home</Link>
                <Link to="/proyects" >Proyectos</Link>
                <Link to="/contact" >Contacto</Link>
            </li>
        </ul>
    )
}