import React  from "react";
import { Typewriter, Cursor } from 'react-simple-typewriter';

import photo from "../assets/self.png";
import "./Home.css"


export const Home = ()=> {

    return(
             <div className="contentHome">

                <div className="containerTyperwiter">
                    <h2>Desarollador &nbsp;</h2><Typewriter
                        words={['Junior', 'Web', 'Fronted', 'Backend', 'Full Stack']}
                        loop={false}
                        typeSpeed={80} // entre mas bajo es mas rapido menor a 90
                        deleteSpeed={61} //tiene que ser mayor a 60
                        delaySpeed={1500}  //delay menor a 1500
                    /><Cursor  />
                </div>

                    <div className="containerPhoto">
                            <img src={photo} alt="foto persona"/>

                    </div>

                    <div className="positionTextDescription">
                        <div className="backgroudText">
                                    <p>Realizador de sueños,
                                        Ingeniero Comercial de profesión. 
                                        <br/> Amante de la tecnologia y Analista Programador por pasión,
                                         te invito a conocer mis proyectos los cuales seran publicados de forma paulatina en este sitio, si necesitas asesoria puedes contactarme <br/>

                                    </p>
                        </div>
                    </div>

            </div>


    )
}