import { Fragment } from "react";
import { Router } from "./router/Router";
import MenuContext from "./context/MenuContext";
import useInitialState from "./hooks/useInitialState";


import './App.css';
 

function App() {
  const inicialState = useInitialState();
  return (
    <Fragment >
      <MenuContext.Provider value={inicialState} >
      <Router />
      </MenuContext.Provider>
    </Fragment>
  );
}

export default App;
