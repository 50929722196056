import { Link } from "react-router-dom";
import { Proyect } from "../components/Proyect"
import data from "../JSON/data.json";
import "./ListProyects.css"

export const ListProyects = ()=>{
    
    let poryects = [];
    poryects = data.proyects;

    return(
        <div className="containerProyects">
            <h1 className="title">Lista de proyectos</h1>

            <div className="wrapProyects">
            {
                poryects.map((proyect) =>{return (<Link key={proyect.id} to={`proyect/${proyect.id}`} >
                    <Proyect 
                         
                        title={proyect.title}
                        urlPage ={proyect.urlPage}
                        resume={proyect.resume}
                    /> 
                    
                    </Link>)})
            }
            </div>
        </div>
    )

}