import {useState} from "react";

const useInitialState = ()=>{
    const [ menu, setMenu ] = useState(false);

    const buttonMenu = ()=>{
        setMenu(!menu);
    }
    
    return{
        menu,
        buttonMenu
    }

}

export default useInitialState;

