import { ListProyects } from "../containers/ListProyects";


import "./Proyects.css";
export const Proyects = ()=>{
    return(
        <div className="containerProyectsPage">
            <ListProyects />
        </div>
        
    )
} 

