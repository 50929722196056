import { useContext } from "react";
import { Nav } from "../containers/Nav";
import { Footer } from "../components/Footer";
import { Menu } from "../components/Menu";
import MenuContext from "../context/MenuContext";
import "./Layout.css"

export const Layout = ({children})=>{

    const {menu} = useContext(MenuContext);
    return(
        <div className="layout">
            <Nav />
           
                {menu ? 
                 <div className="containerMobilMenu">
                < Menu /> 
                </div>:
                 null}

            <div className="layoutPageContent">
            {children}
            </div>
            <Footer />          
        </div>
    )

}