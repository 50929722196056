import  {  Link } from "react-router-dom";
import data from "../JSON/data.json";
import "./ProyectPage.css"
import { BiWorld } from "react-icons/bi";
import { BsGithub } from "react-icons/bs";
import { MdKeyboardReturn } from "react-icons/md";


export const ProyectPage = ()=>{
    

    let proyect = "";

    const url =  window.location.href;
    const id = url.split("/").pop();
    let Filter = data.proyects.filter((el)=>el.id === id);
    if(Filter.length <= 0)
    {   
        //redireccionar        
    }

    proyect = Filter[0];

   

    return(
        <div className="containerProyectPage">
            <h1>{proyect.title}</h1>
            <div className="containerIframeProyectPage">
                <iframe src={proyect.urlPage} title="title"></iframe>

                <div className="wrapdescriptiontext">
                <h3>Descripción</h3>
                <p>{proyect.description}</p>
                <h3>Tecnologias</h3>
                <ul>
                    {
                        proyect.technologies.map(tec => <li key={tec}>{tec}</li>)
                    }
                </ul>
            </div>
            <div className="containerButtonProyectPage">
                    <a href={proyect.urlPage} rel="noreferrer" ><BiWorld/> Ir Pagina del Proyecto</a>
                    {
                        proyect.urlGithub ? <a href={proyect.urlGithub}  rel="noreferrer" > <BsGithub/> GitHub del Proyecto</a>: null
                    }
                
                    <Link to="/proyects"> <MdKeyboardReturn/> vover a proyectos</Link>
            </div>      


        </div>





        </div>
    )

}