
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "../pages/Home";
import { Proyects } from "../pages/Proyects";
import { Contact } from "../pages/Contact";
import { Layout } from "../containers/Layout";
import  {ProyectPage } from "../pages/ProyectPage";
import { NotFound } from "../pages/NotFound";

export const Router = ()=>{

    return(
        <BrowserRouter >
            <Layout>
                <Routes>
                    
                        <Route path="/" element={<Home/>}/>
                        <Route path="/proyects" element={<Proyects/>}/>  
                        <Route path="/proyects/proyect/*" element={<ProyectPage/>}/>                 
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    
                </Routes>
            </Layout>
        </BrowserRouter>
    )
}


