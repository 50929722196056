export const NotFound = ()=>{
    return(
        <div>
            <h3>
                Pagina No encontrada
            </h3>
            <h4>
                Error 404
            </h4>
        </div>
    )
}