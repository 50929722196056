import "./Proyect.css";

export const Proyect = ({title, urlPage, resume})=>{
    return(
        <div className="containerProyect">
            <h1>{title}</h1>
            <div className="wraperProyectIframe">
                <iframe src={urlPage} title={title}></iframe>
            </div>
                <p>{resume}</p>
        </div>
    )

}